<template>
  <div class="onze py-5 text-center text-white" id="realisaties">
    <b-container>
      <h1 class="text-secondry"><b>Onze realisaties</b></h1>
      <h3 class="mb-4"><b>Enkel het beste is goed genoeg.</b></h3>
      <p class="m-auto px-0 px-md-5">
        We begrijpen dat onze gerealiseerde projecten niet enkel gebouwen
        vertegenwoordigen maar ook plannen vormen voor onze toekomstige klanten.
        We gebruiken bekende technieken en technologien om onze maximale
        potentieel te bereiken.
      </p>

      <img src="../assets/Onze1.jpg" width="100%" class="my-5" />
      <div class="row">
        <div class="col">
          <img src="../assets/Onze2.jpg" width="100%" />
        </div>
        <div class="col">
          <img src="../assets/Onze3.jpg" width="100%" />
        </div>
        <div class="col"><img src="../assets/Onze4.jpg" width="100%" /></div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.onze {
  background: #333333 !important;
  & h1 {
    @media only screen and (max-width: 766px) {
      font-size: 28px !important;
    }
  }
  & h3 {
    & b {
      @media only screen and (max-width: 766px) {
        font-size: 22px !important;
      }
    }
  }
  & p {
    width: 75%;
    @media only screen and (max-width: 766px) {
      width: 100% !important;
      font-size: 13px !important;
    }
  }
}
</style>
