<template>
  <div class="home">
    <!-- <NavBar /> -->
    <bedankt />
    <Wat />
    <Voordelen />
    <Onze />
    <Toepassingen />
    <Gevel />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
// import NavBar from "../components/NavBar.vue";
import bedankt from "@/components/Bedankt.vue";
import Wat from "@/components/Wat.vue";
import Voordelen from "../components/Voordelen.vue";
import Onze from "../components/Onze.vue";
import Toepassingen from "../components/Toepassingen.vue";
import Gevel from "../components/Gevel.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Home",
  components: {
    // NavBar,
    bedankt,
    Wat,
    Voordelen,
    Onze,
    Toepassingen,
    Gevel,
    Footer,
  },
};
</script>
