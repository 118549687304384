<template>
  <div class="my-5 wat text-white py-5">
    <NavBar v-if="!showNav" />
    <b-container>
      <b-row>
        <b-col lg="6" sm="12">
          <h1 class="mb-4 pb-2"><b>Wat is spuitkurk?</b></h1>
          <p class="pb-2">
            Spuitkurk is in feite een mengsel van kurk, waterdragende acrylaat
            en hars dat op de gevel gespoten wordt. Meestal wordt het in
            verschillende lagen aangebracht. De kurk isoleert goed, beschermt de
            gevel tegen vocht en kou en is energiebesparend.
          </p>
          <p>
            Gespoten kurk is in verschillende kleuren en korrelgroottes te
            vinden. Dit geeft een spuitkurk gevel erg brede esthetische
            mogelijkheden: van traditioneel taupe tot opvallend roze, het kan
            allemaal!
          </p>
        </b-col>
        <b-col lg="6" sm="12">
          <img src="../assets/wat.jpg" width="100%" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "./NavBar.vue";
export default {
  name: "Wat",
  data() {
    return {
      showNav: true,
    };
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 1400) {
        if (this.showNav) this.showNav = false;
      } else {
        if (!this.showNav) this.showNav = true;
      }
    },
  },
  components: { NavBar },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
.wat {
  background: #333333 !important;
  & h1 {
    color: #0076b5 !important ;
    @media only screen and (max-width: 766px) {
      font-size: 28px !important;
    }
  }
  & p {
    font-size: 14px;
    @media only screen and (max-width: 766px) {
      font-size: 13px !important;
    }
  }
}
</style>
