<template>
  <div class="bg-secondry py-5 text-white gevel">
    <b-container>
      <b-row class="py-2 py-md-5">
        <b-col lg="6" sm="12">
          <h1><b>Gevel renoveren?</b></h1>
          <p>
            AqwaGroup staat voor u klaar. Krijg persoonlijk advies en ontvang uw
            persoonlijke geveldiagnose voor uw gevelrenovatie met
            <b>spuitkurk</b>.
          </p>
        </b-col>
        <b-col lg="6" sm="12" class="text-center py-4">
          <b-button href="#form" pill class="p-2 p-md-4 btnBg text-white w-50"
            >Ontvang uw offerte »
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Gevel",
};
</script>

<style lang="scss">
.gevel {
  & h1 {
    & b {
      @media only screen and (max-width: 766px) {
        font-size: 28px !important;
      }
    }
  }
  & p {
    @media only screen and (max-width: 766px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
  font-family: "Montserrat-Bold" !important;
  .btnBg {
    background: rgba(255, 143, 0, 1) !important;
    border: rgba(255, 143, 0, 1) !important;
    color: white !important;
    font-weight: bold !important;
    @media only screen and (max-width: 766px) {
      font-size: 12px;
    }
    &:hover {
      background: rgb(220, 125, 1) !important;
    }
  }
}
</style>
