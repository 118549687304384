<template>
  <b-container class="form pt-4 text-white px-4 mt-5" id="form">
    <div class="text-center">
      <input type="checkbox" class="hidden-checkbox" v-model="bootCheck" />
      <h4 class="text-center text-white mb-0">Gratis geveldiagnose</h4>
      <img src="../assets/formArrow.gif" width="77px" />
    </div>
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        label="Om hoeveel geve ls gaat het?"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio
          v-model="form.answers"
          :aria-describedby="ariaDescribedby"
          name="some-radios"
          value="3388"
          >1 gevel</b-form-radio
        >
        <b-form-radio
          v-model="form.answers"
          :aria-describedby="ariaDescribedby"
          name="some-radios"
          value="3391"
          >2 gevels</b-form-radio
        >
        <b-form-radio
          v-model="form.answers"
          :aria-describedby="ariaDescribedby"
          name="some-radios"
          value="3394"
          >3 of meer gevels</b-form-radio
        >
        <b-form-select
          class="mt-2"
          v-model="temp_answers.owner"
          :options="owner"
          required
        ></b-form-select>
        <!-- first name -->
        <div>
          <b-form-input
            class="my-2"
            :state="fNameValidation"
            v-model="form.firstname"
            @input="handleFieldInput('firstname')"
            placeholder="Voornaam"
            required
          ></b-form-input>
          <b-form-invalid-feedback :state="fNameValidation">
            Alleen letters zijn toegestaan.
          </b-form-invalid-feedback>
        </div>
        <!-- last name -->
        <div>
          <b-form-input
            class="my-2"
            :state="lNameValidation"
            v-model="form.lastname"
            placeholder="Achternaam"
            @input="handleFieldInput('lastname')"
            required
          ></b-form-input>
          <b-form-invalid-feedback :state="lNameValidation">
            Alleen letters zijn toegestaan.
          </b-form-invalid-feedback>
        </div>
        <!-- email -->
        <b-form-input
          class="my-2"
          :state="emailValidation"
          @input="handleFieldInput('email')"
          v-model="form.email"
          type="email"
          placeholder="E-mailadres"
          required
        ></b-form-input>
        <!-- phone_number -->
        <div>
          <b-form-input
            class="my-2"
            maxlength="10"
            :state="phoneValidation"
            v-model="form.phone_number"
            @input="handleFieldInput('phone_number')"
            type="tel"
            placeholder="Telefoonnummer"
            required
          ></b-form-input>
          <b-form-invalid-feedback :state="phoneValidation">
            Telefoonnummer moet 10 getallen bevatten.
          </b-form-invalid-feedback>
        </div>
        <!-- street -->
        <div class="row">
          <div class="col-8">
            <b-form-input
              class="my-2"
              :state="streetValidation"
              @input="handleFieldInput('street')"
              v-model="form.street"
              type="text"
              placeholder="Straat"
              required
            ></b-form-input>
          </div>
          <!-- house_number -->
          <div class="col-4">
            <b-form-input
              class="my-2"
              :state="houseValidation"
              v-model="form.house_number"
              @input="handleFieldInput('house_number')"
              type="text"
              placeholder="Huisnummer"
              required
            ></b-form-input>
          </div>
        </div>
        <!-- zip code -->
        <b-form-input
          class="my-2"
          :state="zipValidation"
          v-model="form.zip"
          @input="handleFieldInput('zip')"
          maxLength="6"
          type="text"
          placeholder="Postcode"
          required
        ></b-form-input>

        <b-form-checkbox-group
          v-model="temp_answers.answers"
          id="checkbox"
          :aria-describedby="ariaDescribedby"
        >
          <b-form-checkbox value="3406" required class="my-2 checkboxText">
            &nbsp;Ja, ik wens de nieuwsbrief te ontvangen</b-form-checkbox
          >
        </b-form-checkbox-group>
        <!-- submit -->
        <b-button
          type="submit"
          block
          pill
          class="p-3 btnBg text-dark w-100 my-2"
          ><b-spinner v-if="getLoader" /><span v-else>Aanvraag versturen</span>
        </b-button>
        <p class="formEndText">
          Alle gegevens zijn vertrouwelijk en worden behandeld in
          overeenstemming met de Europese wetgeving inzake de bescherming van de
          persoonlijke levenssfeer. Lees meer in ons
          <a href="#form" class="text-white" v-b-modal.PrivacyPolicy>
            privacybeleid.</a
          >
        </p>
        <p class="formEndText">
          Door vrijblijvend dit formulier in te vullen gaat u akkoord met de
          <a
            href="https://www.conversion-impact.be/algemene-voorwaarden.html"
            class="text-white"
            target="_blank"
            >algemene voorwaarden.</a
          >
        </p>
      </b-form-group>
    </b-form>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Form",
  data() {
    return {
      bootCheck: false,
      validationCheck: true,
      emailErr: true,
      temp_answers: {
        answers: [],
        owner: null,
      },
      form: {
        language: "nl_BE",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        street: "",
        house_number: "",
        zip: "",
        site_custom_url: "https://samengoedkoper.verbeterthuis.be/spuitkurk",
        site_custom_name: "Samengoedkoper Spuitkurk",
        answers: [],
        site_subid: "",
        publisher_id: "",
      },
      owner: [
        { text: "Bent u eigenaar?", value: null, disabled: true },
        { text: "Ja", value: "3400" },
        { text: "Nee", value: "3403" },
      ],
    };
  },
  methods: {
    ...mapActions(["postLead"]),
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponse && !this.getErrorResponse[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponse,
          [fieldName]: true,
        });
      }
    },
    async onSubmit() {
      if (this.validationCheck) {
        let arr = this.temp_answers.answers;
        let ans = this.form.answers;
        arr.push(this.temp_answers.owner);
        let uncheckValue = arr.includes("3406");
        if (!uncheckValue) {
          arr.push("3409");
        }
        let checkOne = ans.includes("3388");
        if (checkOne) {
          arr.push("3388");
        }
        let checkTwo = ans.includes("3391");
        if (checkTwo) {
          arr.push("3391");
        }
        let checkThree = ans.includes("3394");
        if (checkThree) {
          arr.push("3394");
        }
        let compare = !checkOne && !checkTwo && !checkThree;
        if (compare) {
          arr.push("3397");
        }

        let resp = await this.postLead({
          ...this.form,
          bootCheck: this.bootCheck,
          answers: [...this.temp_answers.answers, "3385"],
          site_subid: this.$route.query.oa_id,
          publisher_id: this.$route.query.site,
        });
        if (resp.success == true) {
          this.$router.push("bedankt");
        }
      }
    },
  },

  computed: {
    ...mapGetters(["getLoader", "getErrorResponse"]),
    fNameValidation() {
      if (this.form.firstname == "" && !this.getErrorResponse.firstname) {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.getErrorResponse.firstname == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    lNameValidation() {
      if (this.form.lastname == "" && !this.getErrorResponse.lastname) {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;

      if (this.getErrorResponse.lastname == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidation() {
      if (this.form.email == "" && !this.getErrorResponse.email) {
        this.validationCheck = false;
        return null;
      }
      if (this.getErrorResponse.email == false) {
        this.validationCheck = false;
        return false;
      } else if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidation() {
      if (this.form.phone_number == "" && !this.getErrorResponse.phone_number) {
        this.validationCheck = false;
        return null;
      }

      let phoneRegex = /^0\d{9}$/;
      if (this.getErrorResponse.phone_number == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.phone_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    streetValidation() {
      if (this.form.street == "" && !this.getErrorResponse.street) {
        this.validationCheck = false;
        return null;
      }

      let streetRegex = /^[a-zA-Z ]*$/;
      if (this.getErrorResponse.street == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.street.match(streetRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    houseValidation() {
      if (this.form.house_number == "" && !this.getErrorResponse.house_number) {
        this.validationCheck = false;
        return null;
      }

      let streetRegex = /(\w?[0-9]+[a-zA-Z0-9\- ]*)/;
      if (this.getErrorResponse.house_number == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.house_number.match(streetRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    zipValidation() {
      if (this.form.zip == "" && !this.getErrorResponse.zip) {
        this.validationCheck = false;
        return null;
      }
      let zipRegex = /^([0-9]{4})$/;
      if (this.getErrorResponse.zip == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.zip.match(zipRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: "Montserrat", sans-serif !important;
  & h4 {
    font-family: "Montserrat-Bold", sans-serif !important;
  }
}
.hidden-checkbox {
  opacity: 0;
}
.form {
  background: #0076b5;
}
.btnBg {
  background: rgba(255, 143, 0, 1) !important;
  border: rgba(255, 143, 0, 1) !important;
  color: white !important;
  font-weight: bold !important;
  &:hover {
    background: rgb(220, 125, 1) !important;
  }
}
.checkboxText {
  font-size: small;
}
.formEndText {
  font-size: 10px;
}
</style>
