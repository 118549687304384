<template>
  <div class="bg-white fixed-top" id="navbar">
    <b-container class="d-none d-md-block">
      <b-navbar toggleable="lg" type="light" variant="white">
        <b-navbar-brand href="#"
          ><img src="../assets/logo.png" width="40%"
        /></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto align-items-center">
            <b-nav-item href="#voordelen" class="mx-0 mx-md-4"
              >Voordelen</b-nav-item
            >
            <b-nav-item href="#realisaties" class="mx-0 mx-md-4"
              >Realisaties</b-nav-item
            >
            <b-nav-item href="#toepassingen" class="mx-0 mx-md-4"
              >Toepassingen</b-nav-item
            >
            <b-button
              href="#form"
              pill
              variant="warning"
              class="py-2 btnBg text-white"
              >Gratis offerte
            </b-button>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
    <b-container class="d-block d-md-none py-3">
      <div class="text-center">
        <b-button
          href="#form"
          pill
          variant="warning"
          class="py-2 btnBg text-white px-4"
          >Gratis offerte
        </b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style lang="scss">
.nav-link {
  font-weight: bolder !important;
  color: black !important;
  font-family: "Montserrat-Bold" !important;
}
.btnBg {
  background: rgba(255, 143, 0, 1) !important;
  border: rgba(255, 143, 0, 1) !important;
  color: white !important;
  font-weight: bold !important;
  &:hover {
    background: rgb(220, 125, 1) !important;
  }
}
</style>
