<template>
  <b-container class="py-5 toepassingen" id="toepassingen">
    <b-row class="py-5">
      <b-col lg="6" sm="12">
        <h1 class="text-secondry mb-3"><b>Toepassingen</b></h1>
        <p mb-2>
          Doordat spuitkurk de warmte in uw woning goed vasthoudt, zijn
          <b>vochtproblemen opgelost</b>. Kurk isoleert zodanig goed dat
          condensatie en koudebruggen in de woning verdwijnen. Zo krijgen
          schimmels geen kans.
        </p>
        <p mb-2>
          U dient ook minder te verwarmen, waardoor de stookkost aanzienlijk
          daalt. Met kurkisolatie wordt uw woning een ecologisch en
          milieuvriendelijk gebouw.
        </p>
        <p mb-2>Met spuitkurk is de waaier aan mogelijkheden eindeloos:</p>
        <ul class="px-3">
          <li>Rechtstreeks op gevelsteen (ipv verf of crepi)</li>
          <li>Rechtstreeks op cement (mooi strak effect)</li>
          <li>Zowel voor nieuwbouw als renovatie</li>
          <li>Ook mogelijk voor binnenmuren en plafonds</li>
        </ul>
      </b-col>
      <b-col lg="6" sm="12">
        <img
          src="../assets/Toepassingen.jpg"
          width="100%"
          height="385px"
          class="d-none d-md-block"
      /></b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Toepassingen",
};
</script>

<style lang="scss">
.toepassingen {
  font-size: 14px !important;
  & h1 {
    & b {
      @media only screen and (max-width: 766px) {
        font-size: 28px !important;
      }
    }
  }
  & p {
    color: rgb(112, 112, 112) !important;
  }
  & li {
    color: rgb(112, 112, 112) !important;
    font-family: "Montserrat" !important;
  }
}
</style>
