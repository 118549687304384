<template>
  <b-container class="py-5 footer text-center">
    <p>
      Copyright 2022 SamenGoedkoper.be i.s.m. Aqwagroup - Alle rechten
      voorbehouden.
    </p>
    <div class="row text-center justify-content-center">
      <a
        href="https://www.conversion-impact.be/algemene-voorwaarden.html"
        target="_blank"
        >Algemene voorwaarden&nbsp;|&nbsp;</a
      >
      <a href="https://www.conversion-impact.be/gdpr.html" target="_blank"
        >Gegevensbescherming&nbsp;|&nbsp;</a
      >
      <a
        href="https://www.conversion-impact.be/privacy-policy.html"
        target="_blank"
        >Privacybeleid</a
      >
    </div>
  </b-container>
</template>

<script>
export default {};
</script>

<style lang="scss">
.footer {
  & p {
    font-size: 12px;
  }
  & a {
    font-size: 12px;
    @media only screen and (max-width: 766px) {
      font-family: "Montserrat" !important;
    }
  }
}
</style>
