<template>
  <b-container>
    <div class="pt-4">
      <img src="../assets/logo.png" width="160px" />
    </div>
    <b-row>
      <b-col lg="7" sm="12">
        <div class="mt-4 hero">
          <h1 class="hero__title">Spuitkurk</h1>
          <h4 class="hero__subTitle mt-4">
            Dé natuurlijke en milieubewuste gevelbekleding
          </h4>
          <p class="hero__text mt-4">
            Wie op zoek is naar een nieuwe look voor zijn woning, moet zeker ook
            spuitkurk als gevelbekleding overwegen. Gespoten kurk oogt heel net
            en biedt tal van voordelen. En de prijs? Spuitkurk is één van de
            goedkoopste materialen voor gevelbekleding.
          </p>
          <img
            src="../assets/arrow.png"
            class="d-none d-md-block ml-auto"
            width="150px"
          />
        </div>
        <img
          src="../assets/banner.jpg"
          width="100%"
          height="511px"
          class="bottomImg"
        />
      </b-col>
      <!-- form -->
      <b-col lg="5" sm="12">
        <Form />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Form from "../components/Form.vue";
export default {
  name: "Hero",
  components: {
    Form,
  },
};
</script>

<style lang="scss">
.hero {
  &__title {
    color: rgb(51, 51, 51);
    font-size: 84px;
    font-weight: 700;
    @media only screen and (max-width: 766px) {
      font-size: 60px;
    }
  }
  &__subTitle {
    color: rgb(63, 65, 68);
    font-weight: 400;
    font-family: "Montserrat" !important;
    @media only screen and (max-width: 766px) {
      font-size: 17px !important;
      font-family: "Montserrat" !important;
    }
  }
  &__text {
    font-size: 15px;
    color: rgb(112, 112, 112);
    @media only screen and (max-width: 766px) {
      font-size: 13px !important;
    }
  }
  & img {
    margin-top: -20px;
  }
}
.bottomImg {
  @media only screen and (max-width: 766px) {
    height: 43%;
  }
}
.text-secondry {
  color: rgb(0, 118, 181) !important;
}
.bg-secondry {
  background: rgb(0, 118, 181) !important;
}
</style>
