<template>
  <b-container class="voordelen py-5" id="voordelen">
    <b-row>
      <b-col lg="6" sm="12">
        <h1 class="text-secondry mb-2 mb-md-4"><b>Voordelen spuitkurk</b></h1>
        <p>
          Voor spuitkurk kiezen, is kiezen voor betrouwbaarheid. Deze
          revolutionaire methode van gevelbekleding geeft uw huis de zorg die
          het verdient.
        </p>
        <div>
          <div class="mt-4">
            <b-row>
              <div class="col-1">
                <!-- <b-icon icon="circle" class="text-secondry"></b-icon> -->
                <img src="../assets/circle.png" width="35px" />
              </div>
              <div class="col">
                <h6><b>Waterafstotend</b></h6>
                <p class="mt-3">
                  Met gespoten kurk op uw gevel bent u zeker van een waterdichte
                  bescherming. Dankzij zijn <b>ademende functie</b> zorgt
                  spuitkurk ervoor dat vocht tot waterdamp wordt gereduceerd en
                  zo kan ontsnappen. De perfecte oplossing voor geverfde gevels
                  met vochtproblemen!
                </p>
              </div>
            </b-row>
          </div>
          <div class="mt-4">
            <b-row>
              <div class="col-1">
                <img src="../assets/circle.png" width="35px" />
              </div>
              <div class="col">
                <h6><b>Ecologisch</b></h6>
                <p class="mt-3">
                  Spuitkurk is een ecologisch verantwoord alternatief voor
                  gevelbekleding. Kurk is namelijk herbruik- én hernieuwbaar.
                  Kiezen voor spuitkurk is dus een milieubewuste keuze.
                </p>
              </div>
            </b-row>
          </div>
          <div class="mt-4">
            <b-row>
              <div class="col-1">
                <img src="../assets/circle.png" width="35px" />
              </div>
              <div class="col">
                <h6><b>Elastisch en veelzijdig</b></h6>
                <p class="mt-3">
                  Spuitkurk is zo’n handig product dat het zich op alle
                  oppervlakken vasthecht, maakt niet uit of het baksteen, metaal
                  of hout is. Er zit bovendien heel wat rek op, waardoor barsten
                  en scheuren geen kans krijgen.
                </p>
              </div>
            </b-row>
          </div>
          <div class="mt-4">
            <b-row>
              <div class="col-1">
                <img src="../assets/circle.png" width="35px" />
              </div>
              <div class="col">
                <h6><b>En de prijs?</b></h6>
                <p class="mt-3">
                  De prijs van een gevelrenovatie met spuitkurk hangt sterk af
                  van de toestand van de gevel. Maar het is sowieso
                  <b>goedkoper dan crepi</b> en heeft een uitstekende
                  prijs-kwaliteit verhouding
                </p>
              </div>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col lg="6" sm="12" class="mt-5 text-center">
        <img
          src="../assets/Voordelen.jpg"
          class="pt-0 pt-md-4 d-none d-md-block"
          width="100%"
        />
        <b-button
          href="#form"
          pill
          variant="warning"
          class="p-2 p-md-4 btnBg text-white"
          >Ontvang uw offerte »
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Voordelen",
};
</script>

<style lang="scss">
.voordelen {
  & h1 {
    @media only screen and (max-width: 766px) {
      font-size: 28px !important;
    }
  }
  & p {
    color: rgb(112, 112, 112) !important;
    font-size: 14px !important;
    @media only screen and (max-width: 766px) {
      font-size: 13px !important;
    }
  }
  & svg {
    font-size: 30px !important;
  }
  & h6 {
    color: rgb(52, 52, 52) !important;
    font-family: "Montserrat" !important;
    font-size: 17px !important;
    @media only screen and (max-width: 766px) {
      & b {
        font-family: "Montserrat" !important;
      }
    }
  }
  & img {
    @media only screen and (max-width: 766px) {
      width: 25px !important;
    }
  }
  & a {
    margin-top: -38px !important;
  }
  .btnBg {
    background: rgba(255, 143, 0, 1) !important;
    border: rgba(255, 143, 0, 1) !important;
    color: white !important;
    font-weight: bold !important;
    &:hover {
      background: rgb(220, 125, 1) !important;
    }
  }
}
</style>
