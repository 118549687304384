<template>
  <b-container>
    <div class="pt-4">
      <img src="../assets/logo.png" width="160px" />
    </div>
    <b-row>
      <b-col lg="7" sm="12">
        <div class="mt-4 hero">
          <h1 class="hero__title">Spuitkurk</h1>
          <h4 class="hero__subTitle mt-4">
            Dé natuurlijke en milieubewuste gevelbekleding
          </h4>
          <p class="hero__text mt-4">
            Wie op zoek is naar een nieuwe look voor zijn woning, moet zeker ook
            spuitkurk als gevelbekleding overwegen. Gespoten kurk oogt heel net
            en biedt tal van voordelen. En de prijs? Spuitkurk is één van de
            goedkoopste materialen voor gevelbekleding.
          </p>
          <img
            src="../assets/arrow.png"
            class="d-none d-md-block ml-auto"
            width="150px"
          />
        </div>
        <img
          src="../assets/banner.jpg"
          width="100%"
          height="511px"
          class="bottomImg"
        />
      </b-col>
      <!-- form -->
      <b-col lg="5" sm="12">
        <b-container class="bedankt text-center mt-5">
          <h1 class="text-center">Bedankt voor uw aanvraag</h1>
          <p class="text-white">
            AqwaGroup zal zo snel mogelijk contact opnemen voor een bezoek ter
            plaatse en een vrijblijvende offerte uitwerken.
          </p>
          <p class="text-white">
            Meer acties ontdekken? Check
            <a href="https://www.samengoedkoper.be/" target="_blank"
              >SamenGoedkoper.be!</a
            >
            <img
              v-if="profId !== null"
              referrerpolicy="no-referrer-when-downgrade"
              :src="`https://republish.verbeterthuis.be/m/5264/46d8af4d4baf/?event=6161&unique_conversion_id=${profId}`"
              style="width: 1px; height: 1px; border: 0px"
            />
          </p>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Bedankt",
  computed: mapGetters(["profId"]),
};
</script>

<style lang="scss">
.bedankt {
  background: #0076b5;
  height: 94%;
  display: flex;
  height: 94%;
  flex-direction: column;
  justify-content: center;

  & a {
    color: white !important;
    text-decoration: underline !important;
  }
}
</style>
